html, body {
  max-width: 100%;
  overflow-x: hidden;
}

body {
  margin: 0;
}

table, th, td {
  border: 1px solid black;
  border-collapse: collapse;
}

@media print {
  .hideprint {
    display: none !important
  }
  .showprint {
    display: inherit !important
  }
}